<template>
  <div class="classify-wrapper">
    <ul class="classify-ul" ref="tabHeightEl">
      <li v-for="classifyItem in tabList" :key="classifyItem.id" class="item" :class="{activeTab:classifyItem.rightData.tabType===activeTab}" @click="classPage(classifyItem)">
        <p class="title " >
          {{ classifyItem.title }}
        </p>
        <p class="icon">
          <span class="el-icon-arrow-right icon"></span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "classifyList",
  props: {
    //tab数据
    tabList: {
      type: Array,
      default() {
        return []
      }
    },
    //当前选中Tab
    activeTab: {
      type: String
    },
  },
  data() {
    return {}
  },
  methods: {
    getTabListHeight(){
      return this.$refs.tabHeightEl.offsetHeight + 'px'
    },
    classPage(item) {
      this.$emit('handlerRightMsg', item)
    },
  },
};
</script>
<style lang="scss">
.classify-wrapper {
  background-color: #dadada;
  .classify-ul {
    border-right: 1px solid rgb(64, 158, 255);
    .item {
      height: 50px;
      background-color: #fff;
      border-top: 1px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &.activeTab {
        background-color: #f4f4f4;
        .title {
          color: #333;
          font-weight: 700;
        }
        .icon {
          color: rgb(64, 158, 255);
        }
      }
      &:hover {
        background-color: #f4f4f4;
        .title {
          color: #333;
          font-weight: 700;
        }
        .icon {
          color: rgb(64, 158, 255);
        }
      }
      &:first-child {
        border-top: 0;
      }
      .title {
        padding-left: 20px;
        font-size: 18px;
        color: #666;
      }
      .icon {
        margin-right: 5px;
      }
    }
  }
}
</style>
