<template>
  <div class="slideNavigationWrapper">

    <ul class="slideList"
        v-if="scrollTop>=showSideNav"
    >
      <li
          v-for="(sideItem,sideIndex) in sideList"
          :key="sideItem.sideId"
          @click="toFloor(sideIndex)"
          :class="{currentScroll:scrollTab(sideIndex)}"
      >
        <span class="iconfont"></span>
        <p>{{ sideItem.sideTitle }}</p>
      </li>
      <li
          @click="toFloor(-1)"
      >
        <span class="iconfont"></span>
        <p>回到顶部</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideNavigation",
  props: {
    sideList: {
      type: Array,
      required: true
    },
    floorItemHeights: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      timer: null,
      scrollTop: 0,
      currentIndex: -1,
      showSideNav:200,

    }
  },
  mounted() {

    document.onscroll = this.handlerScroll
  },
  methods: {
    toFloor(floorIndex) {
      this.scrollActions(floorIndex === -1 ? 0 : this.floorItemHeights[floorIndex],floorIndex)

    },
    scrollActions(target,floorIndex) {
      let begin,step
      clearInterval(this.timer);
      if(floorIndex===this.floorItemHeights.length-1){
        target = document.body.scrollHeight - document.documentElement.clientHeight
      }
      this.timer = setInterval(() => {
         begin = document.documentElement.scrollTop;
         step = (target - begin) * 0.2;
        begin += step;
        if (Math.abs(Math.floor(step)) <= 1) {
          clearInterval(this.timer);
          document.documentElement.scrollTop = target;
          this.scrollTop = target
          return;
        }
        document.documentElement.scrollTop = begin;
      }, 20);
    },
    handlerScroll() {
      this.scrollTop = document.documentElement.scrollTop
    },
    scrollTab(sideIndex) {
      const {scrollTop, floorItemHeights} = this,
          len = floorItemHeights.length,
          maxScroll = document.body.scrollHeight - document.documentElement.clientHeight
      let currentScroll = floorItemHeights[sideIndex]
      // if (!(sideIndex + 1 >= len)) {
      //   return scrollTop < floorItemHeights[sideIndex + 1] && scrollTop >= currentScroll
      // } else {
      //   return scrollTop >= currentScroll
      // }
        if (sideIndex === len - 1) {
          if(floorItemHeights[sideIndex]>maxScroll ){
            return scrollTop === maxScroll
          }else{
            return scrollTop >= floorItemHeights[sideIndex]
          }
        } else {
            if(floorItemHeights[sideIndex + 1]>maxScroll){
              return scrollTop < maxScroll && scrollTop >= currentScroll
            }else{
              return scrollTop < floorItemHeights[sideIndex + 1] && scrollTop >= currentScroll
            }
        }


    }
  }
}
</script>

<style lang="scss">

.slideNavigationWrapper {
  .slideList {
    position: relative;
    &:before {
      content: '';
      width: 60px;
      height: 60px;
      background: url('~@/assets/images/little-doctor.png') no-repeat;
      position: absolute;
      top: -34px;
      left: -8px;
    }

    li {
      width: 35px;
      height: 40px;
      background-color: #fff;
      margin: 5px auto;
      cursor: pointer;
      box-shadow: 0 0 10px 0px #ccc;
      display: flex;
      align-items: center;

      &.currentScroll {
        background-color: #0080FF;
        color: #fff;
      }

      &:hover {
        background-color: #0080FF;
        color: #fff;
      }

      span {
      }

      p {

        font-size: 12px;
        text-align: center;
        word-wrap: break-word;
        word-break: normal;
        line-height: 15px;
      }
    }
  }
}
</style>
