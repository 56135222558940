<template>
  <div class="scientific-research-wrapper">
    <div class="title flex-rowBetweenColCenter">
      <div class="title-text flex-rowBetweenColCenter">
        <span class="el-icon-caret-right icon"></span>
        <p class="text">科研学术</p>
      </div>
      <span>更多<i class="el-icon-arrow-right"></i></span>
    </div>
    <div class="content-wrapper flex-rowStartColStart">
      <div class="scientific-item-wrapper">
        <div class="scientific-item-title">
          <span>卫星地面端产品及通信技术研究</span>
          <span>更多<i class="el-icon-arrow-right"></i></span>
        </div>
        <div class="list">
          <ul>
            <li
                v-for="(item, index) in hotList"
                :key="index"
            >
              <p>{{ item.title }}</p>
              <p>{{ item.createTime }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="scientific-item-wrapper">
        <div class="scientific-item-title">
          <span>包装箱、温控服研究</span>
          <span >更多<i class="el-icon-arrow-right"></i></span>
        </div>
        <div class="list">
          <ul>
            <li
                v-for="(item, index) in ResearchList"
                :key="index"
            >
              <p>{{ item.title }}</p>
              <p>{{ item.createTime }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="scientific-item-wrapper">
        <div class="scientific-item-title">
          <span>学科文献</span>
          <span>更多<i class="el-icon-arrow-right"></i></span>
        </div>
        <div class="list">
          <ul>
            <li
                v-for="(item, index) in TopicList"
                :key="index"
            >
              <p>{{ item.title }}</p>
              <p>{{ item.createTime }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="scientific-item-wrapper">
        <div class="scientific-item-title">
          <span>热点聚焦</span>
          <span>更多<i class="el-icon-arrow-right"></i></span>
        </div>
        <div class="list">
          <ul>
            <li
                v-for="(item, index) in DocumentList"
                :key="index"
            >
              <p>{{ item.title }}</p>
              <p>{{ item.createTime }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ScientificResearch",
  data() {
    return {
      hotList: [
        {
          title:"当今卫星行业的10个常用技术术语",
          createTime:"2022-08-01"
        },
        {
          title:"长五B火箭成功送梦天！中国天宫，近在咫尺",
          createTime:"2022-10-31"
        },
        {
          title:"用胜利庆祝胜利！长二丁火箭成功发射试验二十号C卫星",
          createTime:"2022-07-19"
        },
        {
          title:"未来战争的核心装备就是它",
          createTime:"2022-08-05"
        },
        {
          title:"美陆军车载激光武器最新进展",
          createTime:"2022-08-29"
        },

      ],
      ResearchList: [],
      TopicList: [],
      DocumentList: [],
    };
  },
  methods: {
    // 截取日期  获取指定的部分
    mapSub(arr) {
      arr.map((item) => {
        item.createTime = item.createTime.substring(0, 10);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.scientific-research-wrapper {
  border-radius: 5px;
  background: #fff;
  margin: 0 auto;
  padding: 30px 0;
  .title {
    margin-bottom: 20px;
    position: relative;
    .title-text {
      .icon {
        width:30px;
        font-size:30px;
        color:#0080ff;
      }
      .text {
        color: #0080ff;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
      }
    }
    span {
      width: 50px;
      text-align: center;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      color: #999;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .content-wrapper {
    flex-wrap: wrap;
    .scientific-item-wrapper {
      width: 48%;
      margin: 0 0 20px 1%;
      .scientific-item-title {
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        border-bottom: 1px solid #e2e8ed;
        span {
          color: #333;
          font-size: 18px;
          font-weight: bold;
          position: relative;
          i {
            position: relative;
            top: 1px;
            font-size: 16px;
            font-weight: bold;
          }
          &:last-child {
            color: #999;
            font-size: 14px;
            position: relative;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.list {
  width: 100%;
}

.list ul {
  width: 100%;
}

li {
  width: 100%;
  border-radius: 4px;
  margin: 10px auto !important;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

li:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgb(133, 133, 133);
  left: 0;
  top: 50%;
  margin-top: -3px;
}

li p:nth-child(1):hover {
  /* box-shadow: 0 0 5px 1px #c0c0c0; */
  color: #0080ff;
  font-size: 16px;
  transition: .1s;
}

li p {
  font-size: 14px;
  padding: 0 10px;
}

li p:nth-child(1) {
  color: #000;
  /* font-weight: bold; */

  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: inline-block;
  width: 80%;
}

li p:nth-child(2) {
  color: #999;
}
</style>
