<template>
  <div class="enterprise-dynamics-wrapper">
    <div class="title flex-rowBetweenColCenter">
      <div class="title-text flex-rowBetweenColCenter">
        <span class="el-icon-caret-right icon"></span>
        <p class="text">企业动态</p>
      </div>
      <span>更多<i class="el-icon-arrow-right"></i></span>
    </div>
    <div class="content-wrapper">
      <ul class="enterprise-dynamic-list flex-rowCenterColStart">
        <li
            v-for="(item,index) in enterpriseDynamicsList"
            :key="index"
        >
          <div class="enterprise-dynamics-item-wrapper flex-rowStartColStart">
            <div class="enterprise-pic"  @click="handlerViewPic(item.enterprisesPicBig)">
              <img v-lazy="item.enterprisesPic" />
            </div>
            <div class="text-content flex-rowBetweenColStart">
              <h1>{{ item.title }}</h1>
              <div class="time-text flex-rowBetweenColCenter">
                <p>{{ item.createTime }}</p>
                <span class="el-icon-arrow-right"></span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

// import errorImg from "@/assets/images/blogErrorImg.jpg";

export default {
  name: "EnterpriseDynamics",
  data() {
    return {
      enterpriseDynamicsList: [
        {
          "searchValue": null,
          "createBy": null,
          "createTime": "2022-07-19 11:31:16",
          "updateBy": null,
          "updateTime": null,
          "remark": null,
          "params": {},
          "id": 4,
          "title": "辰极智航获得第一届“创新杯”国防科技创新大赛集体应用组一等奖",
          "type": null,
          "content": null,
          "top": null,
          "views": 238,
          "status": null,
          "enterprisesPic": require('@/assets/websiteImages/home/enterprise1.png'),
          "enterprisesPicBig":require('@/assets/websiteImages/home/enterpriseBig1.png'),
          "blogDesc": null,
          "typeIds": null,
          "tagIds": null,
          "blogClassifies": null,
          "blogTags": null
        },
        {
          "searchValue": null,
          "createBy": null,
          "createTime": "2022-07-19 11:31:18",
          "updateBy": null,
          "updateTime": null,
          "remark": null,
          "params": {},
          "id": 5,
          "title": "辰极智航参加“无形截击-2019”阿拉善比赛活动荣获民企第一名",
          "type": null,
          "content": null,
          "top": null,
          "views": 59,
          "status": null,
          "enterprisesPic": require('@/assets/websiteImages/home/enterprise2.png'),
          "enterprisesPicBig":require('@/assets/websiteImages/home/enterpriseBig2.png'),
          "blogDesc": null,
          "typeIds": null,
          "tagIds": null,
          "blogClassifies": null,
          "blogTags": null
        },
        {
          "searchValue": null,
          "createBy": null,
          "createTime": "2022-07-19 11:39:28",
          "updateBy": null,
          "updateTime": null,
          "remark": null,
          "params": {},
          "id": 6,
          "title": "辰极智航中标三光吊舱项目",
          "type": null,
          "content": null,
          "top": null,
          "views": 32,
          "status": null,
          "enterprisesPic": require('@/assets/websiteImages/home/enterprise3.png'),
          "enterprisesPicBig":require('@/assets/websiteImages/home/enterpriseBig3.png'),
          "blogDesc": null,
          "typeIds": null,
          "tagIds": null,
          "blogClassifies": null,
          "blogTags": null
        },
        {
          "searchValue": null,
          "createBy": null,
          "createTime": "2022-07-19 11:39:28",
          "updateBy": null,
          "updateTime": null,
          "remark": null,
          "params": {},
          "id": 6,
          "title": "辰极智航参加阿拉善比赛民企第一名",
          "type": null,
          "content": null,
          "top": null,
          "views": 32,
          "status": null,
          "enterprisesPic": require('@/assets/websiteImages/home/enterprise4.png'),
          "enterprisesPicBig":require('@/assets/websiteImages/home/enterpriseBig4.png'),
          "blogDesc": null,
          "typeIds": null,
          "tagIds": null,
          "blogClassifies": null,
          "blogTags": null
        },
        // {
        //   "searchValue": null,
        //   "createBy": null,
        //   "createTime": "2022-07-19 11:39:28",
        //   "updateBy": null,
        //   "updateTime": null,
        //   "remark": null,
        //   "params": {},
        //   "id": 6,
        //   "title": "辰极智航（北京）科技有限公司2022年9月份业绩",
        //   "type": null,
        //   "content": null,
        //   "top": null,
        //   "views": 32,
        //   "status": null,
        //   "enterprisesPic": require('@/assets/websiteImages/home/enterprise3.png'),
        //   "blogDesc": null,
        //   "typeIds": null,
        //   "tagIds": null,
        //   "blogClassifies": null,
        //   "blogTags": null
        // },
      ],
    };
  },
  methods:{
    handlerViewPic(src){
      this.$viewImage.viewShow(src)
    }
  },
  computed:{
    srcList(){
      return this.enterpriseDynamicsList.map(v=>v.enterprisesPic)
    }
  }
};
</script>
<style scoped lang="scss">
.enterprise-dynamics-wrapper {
  border-radius: 10px;
  background: #fff;
  margin: 20px auto;
  padding: 30px 0;
  .title {
    margin-bottom: 20px;
    position: relative;
    .title-text {
      .icon {
        width:30px;
        font-size:30px;
        color:#0080ff;
      }
      .text {
        color: #0080ff;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
      }
    }
    span {
      width: 50px;
      text-align: center;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      color: #999;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .content-wrapper {
    padding: 0 30px;
    .enterprise-dynamic-list {
      width: 100%;
      flex-wrap: wrap;
      li {
        width:calc( ( 100% - 30px ) / 2 );
        border-radius: 4px;
        margin: 20px 30px 10px 0;
        line-height: 30px;
        box-sizing: border-box;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
        &:nth-child(even){
          margin-right: 0;
        }
        .enterprise-dynamics-item-wrapper {
          padding: 20px;
          border-radius: 5px;
          cursor: pointer;
          box-shadow:  0 0 10px 0 rgba(0,0,0,.1);
          .enterprise-pic {
            img {
              display: block;
              width:140px;
              height:140px;
            }
          }

          .text-content {
            flex:1;
            height:140px;
            margin-left: 20px;
            flex-direction: column;


            h1 {
              max-width: 100%;
              color:#333;
              font-size: 18px;
              font-weight: bold;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              line-height:27px;
              &:hover {
                color: rgb(64, 158, 255);

              }
            }

            .time-text {
              border-top:1px solid #dfdfdf;
              width:100%;
              color:#666666;
              line-height:27px;
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

