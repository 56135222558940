<template>
  <div class="product-manual-wrapper">
    <div class="title flex-rowBetweenColCenter">
      <div class="title-text flex-rowBetweenColCenter">
        <span class="el-icon-caret-right icon"></span>
        <p class="text">产品手册</p>
      </div>
      <span>更多<i class="el-icon-arrow-right"></i></span>
    </div>
    <div class="content">
      <div class="company-card-wrapper flex-rowBetweenColStart">
        <el-card
            :body-style="{ padding: '0px' }"
            v-for="(item, index) in productList"
            :key="index"
        >
          <div class="flex-rowCenterColCenter pic"
               style="background-color: #fff;border-bottom:1px solid #ccc;border-radius:5px 5px 0 0;">
            <img v-lazy="item.cover"/>
          </div>
          <div class="content-text">
            <p class="singleRow content-title">{{ item.title }}</p>
            <p class="downloadWrapper">
              <a :href="item.linkProduct" target="_blank">查看</a>
              <a :href="item.linkProduct" :download="item.title">下载</a>
            </p>
          </div>

        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductManual",
  data() {
    return {
      productList: [
        {
          "cover": require('@/assets/websiteImages/home/manual1.png'),
          "linkProduct":"/cjzh-product.pdf",
          "id": 42,
          "title": "辰极智航（北京）科技有限公司",
        },
        {
          "cover": require('@/assets/websiteImages/home/manual3.png'),
          "linkProduct":"/cjfw-product.pdf",
          "id": 42,
          "title": "辰极防务（北京）科技有限公司",
        },
        {
          "cover": require('@/assets/websiteImages/home/manual4.png'),
          "linkProduct":"/wzry-product.pdf",
          "id": 42,
          "title": "王者荣耀（北京）科技有限公司",
        },
        {
          "cover": require('@/assets/websiteImages/home/manual2.png'),
          "linkProduct":"/cjkj-product.pdf",
          "id": 42,
          "title": "辰极科技（北京）科技有限公司",
        },
      ],
    };
  }
};
</script>
<style lang="scss">
.product-manual-wrapper {
  border-radius: 5px;
  background: #fff;
  margin: 20px auto;
  padding: 30px 0;

  .title {
    margin-bottom: 20px;
    position: relative;
    .title-text {
      .icon {
        width:30px;
        font-size:30px;
        color:#0080ff;
      }
      .text {
        color: #0080ff;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
      }
    }
    span {
      width: 50px;
      text-align: center;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      color: #999;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .content {
    .company-card-wrapper {
      .el-card {
        flex:1;
        min-height: 310px;
        margin: 0 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
        cursor: pointer;
        .pic {
          width: 100%;
          height: 200px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .content-text {
          padding: 20px 30px 20px;
          .content-title {
            text-align: center;
            width: 100%;
            color: #333;
            font-size: 14px;
            font-weight: bold;
            line-height: 1;
          }
          .downloadWrapper {
            margin-top: 10px;
            text-align: right;
            a {
              color: #1890ff;
              font-weight: normal;
              font-size: 14px !important;
              line-height: 41px;
              margin: 0 5px;
            }
          }
        }

        .el-carousel {
          img {
            width: 100%;
            height: 100%;
          }

          .el-carousel__indicators {
            display: none;
          }
        }
      }
    }
  }
}
</style>
