<template>
  <div class="employee-wind-wrapper">

    <div class="title flex-rowBetweenColCenter">
      <div class="title-text flex-rowBetweenColCenter">
        <span class="el-icon-caret-right icon"></span>
        <p class="text">员工风采</p>
      </div>
      <span>更多<i class="el-icon-arrow-right"></i></span>
    </div>
    <div class="content flex-rowCenterColCenter">
      <div class="company-card-wrapper flex-rowStartColStart">
        <el-card
            :body-style="{ padding: '0px' }"
            v-for="(item, index) in employeeList"
            :key="index"

        >
          <div class="flex-rowCenterColCenter pic"
               @click="handlerViewPic(item.coverBig)"
               style="background-color: #fff;border-bottom:1px solid #ccc;border-radius:5px 5px 0 0;">
            <img v-lazy="item.cover"/>
          </div>
          <div class="content-text">
            <p class="content-title">{{ item.title }}</p>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EmployeeWind",
  data() {
    return {
      employeeList:  [
        {
          "cover": require('@/assets/websiteImages/home/elegantManners1.png'),
          "coverBig":require('@/assets/websiteImages/home/elegantMannersBig1.png'),
          "id": 42,
          "title": "公司庆祝中标三光吊舱项目市场部聚餐",
        },
        {
          "cover": require('@/assets/websiteImages/home/elegantManners2.png'),
          "coverBig":require('@/assets/websiteImages/home/elegantMannersBig2.png'),
          "id": 42,
          "title": "我司无人机飞手在定州测试场地就行玩人机测试",
        },
        {
          "cover": require('@/assets/websiteImages/home/elegantManners3.png'),
          "coverBig":require('@/assets/websiteImages/home/elegantMannersBig3.png'),
          "id": 42,
          "title": "2020年8月28日园博园无人机拍摄",
        },
        {
          "cover": require('@/assets/websiteImages/home/elegantManners4.png'),
          "coverBig":require('@/assets/websiteImages/home/elegantMannersBig4.png'),
          "id": 42,
          "title": "徐婷婷老师为分子公司员工进行建设培训",
        },
      ],
    };
  },
  methods:{
    handlerViewPic(src){
      this.$viewImage.viewShow(src)
    }
  }
};
</script>
<style lang="scss">
.employee-wind-wrapper {
  border-radius: 5px;
  background: #fff;
  margin: 20px auto;
  padding: 30px 0;

  .title {
    margin-bottom: 20px;
    position: relative;
    .title-text {
      .icon {
        width:30px;
        font-size:30px;
        color:#0080ff;
      }
      .text {
        color: #0080ff;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
      }
    }
    span {
      width: 50px;
      text-align: center;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      color: #999;
      i {
        position: relative;
        top: 1px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .content {
    .company-card-wrapper {

      .el-card {
        position: relative;
        margin-right:20px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
        cursor: pointer;
        &:last-child{
          margin-right: 0;
        }
        .pic {
          width:270px;
          height:290px;
          img {
            display: block;
            width: 100%;
            height:100%;
          }
        }
        .content-text {

          padding: 4px 30px 4px;
          position: absolute;
          left:0;
          right:0;
          bottom:0;
          background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.9));
          .content-title {

            text-align: center;
            width: 100%;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            line-height: 29px;
            margin: 10px 0;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }
          .text {
            text-align: center;
            width: 100%;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            line-height: 1;
          }

        }

        .el-carousel {
          img {
            width: 100%;
            height: 100%;
          }

          .el-carousel__indicators {
            display: none;
          }
        }
      }
    }
  }
}
</style>
